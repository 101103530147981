import { ProjectFields } from '../types'
import { FrameLine, RightLine, LeftLine } from './Lines'

interface Props extends ProjectFields {
  isRight: boolean
}

const Project = ({ name, shortDescription, picture, link, isRight, tags, sourceCodeLink }: Props) => {
  return (
    <div className={`w-full md:flex ${isRight && 'md:flex-row-reverse'} my-8`}>
      <div className="w-full md:w-1/2 relative">
        <a href={link} target="blank">
          <h3 className="text-center">{name}</h3>
        </a>
        {isRight ? <RightLine /> : <LeftLine />}
        <div className={`${isRight ? 'text-left pl-12' : 'text-right pr-12'}`}>
          <div className="text-lg mt-8">
            <a href={link} target="blank">
              {name}
            </a>
            {shortDescription}
          </div>
          <div className="text-green mt-4">{tags}</div>
          <div className="mt-4">
            <a href={sourceCodeLink} target="blank">
              Code
            </a>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 relative">
        <a href={link} target="blank">
          <FrameLine />
          <div className="p-[4%] w-full">
            <img src={`./projects/${picture}`} alt={name} className="w-full object-cover" />
          </div>
        </a>
      </div>
    </div>
  )
}

export default Project
