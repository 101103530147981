import React from 'react'
import ReactDOM from 'react-dom'
import './global.css'
import Portifolio from './components/Portifolio'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { CVPage } from './components/CV'
import { isMobile } from 'react-device-detect'
import { BirdGenerator } from './components/BirdGenerator'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Portifolio />,
  },
  {
    path: '/cv',
    element: isMobile ? <Portifolio /> : <CVPage />,
  },
  {
    path: '/bird-generator',
    element: <BirdGenerator />,
  },
])

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
)
