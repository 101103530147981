import { Link } from 'react-router-dom'
import { CVLink } from './CV'
import { isMobile } from 'react-device-detect'
import { CenterLine } from './Lines'
import Project from './Project'
import projects from '../data/projects'

const Portifolio = () => {
  return (
    <div className="flex justify-center pt-4">
      <div className="w-full md:w-3/4">
        <div className="flex flex-wrap text-center w-full justify-center">
          <div>
            <h1 className="w-full px-10 md:px-0">Otju</h1>
            <h2 className="w-full mt-10">About me</h2>
            <CenterLine />
            <div className="w-full text-lg mt-4">
              Hi! I'm Otto, a full stack developer and Computer Science student at Aalto university
            </div>
            <div className="text-lg mt-4 flex flex-wrap text-center justify-center">
              <a href="https://github.com/Otju" target="blank" className="mx-4">
                GitHub
              </a>
              {isMobile ? <CVLink text="CV" /> : <Link to="cv">CV</Link>}
              <a href="https://www.linkedin.com/in/Otju" target="blank" className="mx-4">
                LinkedIn
              </a>
            </div>
            <h2 className="w-full mt-24">Projects</h2>
            <div className="mb-10 w-full">
              <CenterLine />
            </div>
          </div>
          {projects.map((project, i) => (
            <Project key={project.name} {...project} isRight={i % 2 === 0} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Portifolio
