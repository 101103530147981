import {} from '@react-pdf/renderer'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  PDFDownloadLink,
  Link,
  Image,
} from '@react-pdf/renderer'
import projects from '../data/projects'

const colors = {
  black: '#252d3b',
}

const educationInfo = ['Computer Science', 'Aalto University', '2022-', ' ', 'UH Open University', '40 ECTS Credits']

const skillInfo = [
  'React',
  'TypeScript',
  'Node.js',
  'Git',
  'Vue',
  'Scrum',
  'Jira',
  'MongoDB',
  'Docker',
  'Scala',
  'SQL',
  'Python',
  'Java',
  'AWS',
  'Azure',
  'Google Cloud',
]

const volunteerInfo = ['Aalto Gamers ry 2022-', 'Vuorenhaltiat ry 2017-']

Font.register({ family: 'Roboto', src: './fonts/Roboto-Thin.ttf', fontWeight: 'thin' })
Font.register({ family: 'Roboto', src: './fonts/Roboto-Light.ttf', fontWeight: 'light' })
Font.register({ family: 'Roboto', src: './fonts/Roboto-Regular.ttf', fontWeight: 'normal' })
Font.register({ family: 'Roboto', src: './fonts/Roboto-Medium.ttf', fontWeight: 'medium' })
Font.register({ family: 'Roboto', src: './fonts/Roboto-Bold.ttf', fontWeight: 'bold' })
Font.register({ family: 'Roboto', src: './fonts/Roboto-Black.ttf', fontWeight: 'black' })

Font.register({ family: 'Roboto', src: './fonts/Roboto-ThinItalic.ttf', fontWeight: 'thin', fontStyle: 'italic' })
Font.register({ family: 'Roboto', src: './fonts/Roboto-LightItalic.ttf', fontWeight: 'light', fontStyle: 'italic' })
Font.register({ family: 'Roboto', src: './fonts/Roboto-Italic.ttf', fontWeight: 'normal', fontStyle: 'italic' })
Font.register({ family: 'Roboto', src: './fonts/Roboto-MediumItalic.ttf', fontWeight: 'medium' })
Font.register({ family: 'Roboto', src: './fonts/Roboto-BoldItalic.ttf', fontWeight: 'bold', fontStyle: 'italic' })
Font.register({ family: 'Roboto', src: './fonts/Roboto-BlackItalic.ttf', fontWeight: 'black', fontStyle: 'italic' })

const leftSideWidth = 220
const iconSize = 16

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    color: colors.black,
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  topBar: {
    textAlign: 'center',
    padding: 20,
    fontSize: 34,
    fontWeight: 'bold',
  },
  listSegment: {
    width: leftSideWidth,
    marginBottom: 30,
    textAlign: 'right',
  },
  segmentHeader: {
    fontSize: 20,
    fontWeight: 'black',
    width: '100%',
    marginBottom: 10,
  },
  listElement: {
    fontSize: 15,
    width: '100%',
  },
  subListElement: {
    fontSize: 13,
    fontStyle: 'italic',
  },
  link: {
    color: colors.black,
  },
  projectSegmentList: {
    textAlign: 'left',
    paddingLeft: 40,
    paddingRight: 40,
    width: '75%',
  },
  projectPointElement: {
    fontSize: 12,
    marginLeft: 20,
  },
  bulletPoint: {
    fontSize: 12,
    marginLeft: 20,
    marginTop: 1,
    marginBottom: 1,
  },
  smallText: {
    fontSize: 12,
  },
  projectHeader: {
    fontSize: 20,
  },
  withBottomMargin: {
    marginBottom: 10,
  },
  basic: {
    color: colors.black,
    textDecoration: 'none',
  },
  subText: {
    fontSize: 10,
    marginTop: -15,
    marginBottom: 10,
    width: '100%',
  },
  extraMargin: {
    marginTop: 14,
  },
  icon: {
    width: iconSize,
    height: iconSize,
    marginLeft: 2,
  },
})

type ListSegmentProps = {
  title: string
  elements: string[] | JSX.Element[]
}

const contactInfoText = [
  'ottoj.soderman@gmail.com',
  'Espoo, Finland',
  'otju.dev',
  <Link src="https://www.linkedin.com/in/otju/" style={styles.basic}>
    <Text style={styles.basic}>linkedin.com/in/Otju/</Text>
  </Link>,
  <Link src="https://www.github.com/Otju" style={styles.basic}>
    <Text>github.com/Otju</Text>
  </Link>,
]

const contactIcons = ['mail', 'environment', 'global', 'linkedin', 'github']

const contactInfo = contactInfoText.map((text, i) => (
  <>
    <Text style={{ width: leftSideWidth - iconSize, marginBottom: 1 }}>{text}</Text>
    <Image src={`./icons/${contactIcons[i]}.png`} style={styles.icon} />
  </>
))

const ListSegment: React.FC<ListSegmentProps> = ({ title, elements }) => {
  return (
    <View style={styles.listSegment}>
      <Text style={styles.segmentHeader}>{title}</Text>
      {title === 'Skills' && <Text style={styles.subText}>(by decreasing order of experience)</Text>}
      {elements.map((element, i) => {
        return (
          <View
            key={i}
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: 14,
            }}
          >
            {typeof element === 'string' ? <Text style={{ width: leftSideWidth }}>{element}</Text> : element}
          </View>
        )
      })}
    </View>
  )
}

type ProjectSegmentProps = {
  title: string
  elements: string[]
  link?: string
  sourceCodeLink?: string
}

const ProjectSegment: React.FC<ProjectSegmentProps> = ({ title, elements, link, sourceCodeLink }) => {
  return (
    <View style={styles.withBottomMargin}>
      <Text style={styles.listElement}>{title}</Text>
      {[link, sourceCodeLink].map((link) => (
        <>
          {link && (
            <Link src={link} style={styles.basic}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Image
                  src={link.includes('github') ? `./icons/github.png` : `./icons/global.png`}
                  style={{ ...styles.icon, marginRight: 2 }}
                />
                <Text style={styles.smallText}>{link.replace('https://', '')}</Text>
              </View>
            </Link>
          )}
        </>
      ))}
      {elements.map((element) => (
        <Text key={element} style={styles.bulletPoint}>
          • {element}
        </Text>
      ))}
    </View>
  )
}

type WorkExperience = {
  title: string
  company: string
  time: string
  bulletPoints?: string[]
}

const workExperience: WorkExperience[] = [
  {
    title: 'Frontend Developer Trainee',
    company: 'CSC – IT Center for Science',
    time: '6.-8.2023',
    bulletPoints: [
      "Worked as a frontend developer on CSC's Fairdata-PAS service",
      "Did a complete overhaul of the frontend's design and logic with Vue.js and Bootstrap, in accordance to design specs given to me",
      'Wrote new E2E tests with Cypress, unit tests with Jest, and improved existing ones',
      'Made (small) changes and new features to the Python backend(s) of the project',
      "Reviewed other developers' code, and submitted my own code for review",
      'Created, expanded and clarified issues on Jira',
      'Attended Scrum dailies, sprint plannings, reviews and retrospectives',
    ],
  },
  {
    title: 'Election Official',
    company: 'The City of Helsinki',
    time: '3.2023 & 5.-6.2021',
  },
  {
    title: 'Customer Assistant',
    company: 'Lidl Finland',
    time: '6.-8.2020',
  },
]

export const PDF = (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.topBar}>
        <Text>Otto Söderman</Text>
      </View>
      <View style={styles.mainContent}>
        <View>
          <ListSegment title="Contact" elements={contactInfo} />
          <ListSegment title="Skills" elements={skillInfo} />
          <ListSegment title="Education" elements={educationInfo} />
          <ListSegment title="Volunteer Work" elements={volunteerInfo} />
        </View>
        <View style={styles.projectSegmentList}>
          <Text style={{ ...styles.segmentHeader }}>Work Experience</Text>
          {workExperience.map(({ title, company, time, bulletPoints }) => (
            <View key={time} style={{ marginBottom: 6 }}>
              <Text style={{ ...styles.listElement, fontWeight: 'medium' }}>
                {title}
                {'  '}
                <Text style={styles.subListElement}>{time}</Text>
              </Text>
              <Text style={styles.subListElement}>{company}</Text>
              {bulletPoints &&
                bulletPoints.map((item) => (
                  <Text key={item} style={styles.bulletPoint}>
                    • {item}
                  </Text>
                ))}
            </View>
          ))}
          <Text style={{ ...styles.segmentHeader, ...styles.extraMargin }}>Projects</Text>
          {projects.slice(0, 3).map(({ name, bulletPoints, link, sourceCodeLink }) => (
            <ProjectSegment
              key={name}
              title={name}
              elements={bulletPoints}
              link={link}
              sourceCodeLink={sourceCodeLink}
            />
          ))}
        </View>
      </View>
    </Page>
  </Document>
)

type Props = {
  text: string
}

export const CVLink: React.FC<Props> = ({ text }) => {
  return (
    <PDFDownloadLink document={PDF} fileName="CV Otto Söderman">
      {text}
    </PDFDownloadLink>
  )
}

export const CVPage = () => {
  return <PDFViewer className="w-full h-full">{PDF}</PDFViewer>
}
