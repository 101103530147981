export const FrameLine = () => <img src="./lines/frame.svg" alt="frame" className="absolute w-full" />

export const RightLine = () => (
  <img src="./lines/right.svg" alt="line" className="w-10/12 ml-[17%] md:ml-0 md:w-full mt-[-8px]" />
)

export const LeftLine = () => <img src="./lines/left.svg" alt="line" className="w-10/12 md:w-full mt-[-8px]" />

export const CenterLine = () => (
  <img src="./lines/middle.svg" alt="line" className="w-full px-10 md:px-0 w-max-[800px]" />
)
